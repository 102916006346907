<template>
  <v-app>
    <v-snackbar v-model="snackbar">
      <v-icon class="primary--text pa-1">mdi-alert</v-icon>
      <span class="white--text"
        >Mauvais e-mail ou mot de passe. Veuillez réessayer.</span
      >
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col style="margin-top: -100px" cols="12" sm="8" md="6">
          <v-card class="opa elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Authentification Petit Futé</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon large target="_blank" v-on="on">
                    <v-icon>mdi-login</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Se connecter</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="valid" ref="form" :lazy-validation="false">
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  name="Email"
                  :rules="emailRules"
                  prepend-icon="mdi-email"
                  type="email"
                ></v-text-field>
                <v-text-field
                  id="password"
                  :rules="nameRules"
                  v-model="password"
                  label="Mot de passe"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  @keyup.enter="login"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="">
             <v-row class="d-flex flex-column justify-end align-center center space-around">
                <v-col cols="3">
                    <v-btn
                      type="submit"
                      :disabled="!valid"
                      :loading="loading"
                      @click="login"
                      color="primary"
                    >
                      <v-icon>mdi-lock</v-icon>Se connecter
                    </v-btn>
                </v-col>
          
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>


<script>
import xhr from "../plugins/axios";
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBRdPtG4HaY4LMh9bYSUnMnKsXYvY9HAAY",
  authDomain: "authentification-fdnauc.firebaseapp.com",
  databaseURL: "https://authentification-fdnauc.firebaseio.com",
  projectId: "authentification-fdnauc",
  storageBucket: "authentification-fdnauc.appspot.com",
  messagingSenderId: "126825964385",
  appId: "1:126825964385:web:ee5358af3e22157852db20",
  measurementId: "G-47VM6GFGFT"
};

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { OAuthProvider } from "firebase/auth";

const firebase = initializeApp(firebaseConfig);

export default {
  name: "Login",
  data() {
    return {
      snackbar: false,
      loading: false,
      valid: true,
      email: "",
      password: "",
      nameRules: [
        (v) => !!v || "Mot de passe obligatoire",
        (v) =>
          (v && v.length >= 8) ||
          "Mot de passe doit faire au moins 8 caractères",
      ],
      emailRules: [
        (v) => !!v || "E-mail obligatoire",
        (v) => /.+@.+\..+/.test(v) || "E-mail doit être valide",
      ],
    };
  },
  created(){
  },
  methods: {
    liAuth(){
      IN.User.authorize( ()=> {
        this.back(data);
      });
    },
    back(data){
       IN.API.Profile("me").fields(["id", "email-address"])
        .result( (result) => {
          this.setLoginBadge(result.values[0]);
        })
      console.log("data", data);
    },
    setLoginBadge(profile) {
      console.log("profile", profile);
    },
    OauthApple(){
       const auth = getAuth();
      const provider = new OAuthProvider('apple.com');

      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({
        // Localize the Apple authentication screen in French.
        locale: 'fr'
      });

      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
          

          console.log(result, "result")
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          console.log(error, "error")
          // ...
        });

    },
    OauthLn() {
      const displayProfileData = (data) =>{
        console.log("data", data);
      } 
      const onError = (error) =>{
        console.log(error);
      }
      const  getProfileData = () => {
          IN.API.Profile("me").fields("id", "first-name", "last-name", "headline", "location", "picture-url", "public-profile-url", "email-address").result(displayProfileData).error(onError);
        }

      IN.Event.on(IN, "auth", getProfileData);
      console.log("ici");
    },
    Oauth(){
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;

          localStorage.setItem("id", user.uid);
          localStorage.setItem("email", user.email);
          localStorage.setItem("url", user.photoURL);
          localStorage.setItem("name", user.displayName);
          localStorage.setItem("user", JSON.stringify(user));

          window.location = "/";

          // IdP data available using getAdditionalUserInfo(result)
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);

          console.log('error', error)
          // ...
        });
    },
    login() {
      this.loading = true;
      xhr
        .post("/login", { email: this.email, password: this.password })
        .then((res) => {
          if (!res.data || res.data === null || res.data.success === false ) {
            this.snackbar = true;
            this.loading = false;
          } else if (res.data.success === true) {
              this.loading = false;

              localStorage.setItem("id", res.data._id);
              localStorage.setItem("email", res.data.email);
              localStorage.setItem("user", JSON.stringify(res.data));

              console.log(this.admin)
           
              window.location = "/";
          }
        });
    },
  },
};
</script>